import { provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core';
import { provideRouter, withDisabledInitialNavigation, withInMemoryScrolling } from '@angular/router';
import { provideBcfAppId, provideBcfReleaseVersion } from '@bcf-v2-configurators/tokens/app/provider';
import { status0RetryInterceptor } from '@bcf-v2-shared/misc/interceptors/status0-retry-interceptor';
import { provideOptiSW } from '@bcf-v2-utilities/opti-sw/opti-sw-provider';
import { buildSettings } from '@env/build-settings';
import { releaseVersion } from '@env/release-version';
import { bcfAppId } from '../bcf-app-id';
import { envAppProvidersBrowser } from '../env-app-providers.browser';
import { appBrowserProviders } from './app.providers';
import { routes } from './app.routes';

const appRuntimeId: number = new Date().getTime();
const worker: Worker = new Worker(new URL(/* webpackChunkName: "app-worker" */ '../worker', import.meta.url));

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    provideRouter(routes, withDisabledInitialNavigation(), withInMemoryScrolling({ scrollPositionRestoration: 'top' })),
    provideHttpClient(withFetch(), withInterceptorsFromDi(), withInterceptors([status0RetryInterceptor])),
    // sports-mobile-ngsw-worker.js check in gae deploy script
    provideOptiSW('sports-mobile-ngsw-worker.js', {
      enabled: !buildSettings.isDevServer && buildSettings.environment === 'prod'
    }),
    provideBcfAppId(bcfAppId),
    provideBcfReleaseVersion(releaseVersion),
    ...envAppProvidersBrowser(worker, appRuntimeId),
    ...appBrowserProviders
  ]
};
