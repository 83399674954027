import { NgComponentOutlet } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import type { OptimoveSdkInitializerSmartComponent } from '@bcf-v2-ui-others-components-sportsbook-lvbetpl/mobile-web/optimove-sdk-initializer-smart/optimove-sdk-initializer-smart.component';
import { AppLoadedService } from '@bcf-v2-ui-shared/services/app-loaded.service';
@Component({
  standalone: true,
  selector: 'bcf-root',
  imports: [RouterOutlet, NgComponentOutlet],
  templateUrl: './app.component.html',
  providers: []
})
export class AppComponent implements OnInit {
  public optimoveSdkInitializerSmartComponent: typeof OptimoveSdkInitializerSmartComponent | undefined;

  constructor(
    private _injector: Injector,
    private _appLoadedService: AppLoadedService
  ) {}

  public ngOnInit(): void {
    this._initApp();
    this._initOptimoveSdk();
    this._appLoadedService.init();
  }

  private async _initApp(): Promise<void> {
    this._injector
      .get(
        (await import(/* webpackChunkName: "app-initializator" */ './app-initializator'))
          .LazyInitializatorSbLetsbetmdMobileWeb
      )
      .init();
  }

  private _initOptimoveSdk(): void {
    import(
      /* webpackChunkName: "optimove-initializer" */ '@bcf-v2-ui-others-components-sportsbook-lvbetpl/mobile-web/optimove-sdk-initializer-smart/optimove-sdk-initializer-smart.component'
      // eslint-disable-next-line @typescript-eslint/typedef
    ).then((mod) => (this.optimoveSdkInitializerSmartComponent = mod.OptimoveSdkInitializerSmartComponent));
  }
}
