import { EnvironmentSports } from '@bcf-shared-settings/settings/types';
import { environmentBase } from 'configs/environments-sports/sportsbook/letsbetmd/environment.base';

export const environment: EnvironmentSports = {
  ...environmentBase,
  matchOfferApiUrl: 'https://offer.letsbetmd.com/client-api/v5/',
  matchOfferSocketUrl: 'wss://offer-ws.letsbetmd.com/_v5/ws/update/',
  matchOfferSocketMatchStatsUrl: 'wss://offer-ws-statistics.letsbetmd.com/_v1/',
  bpApiUrl: 'https://api.letsbetmd.com/',
  terminalAssetsUrl: 'https://terminal-letsbetmd.testowaplatforma123.net/terminal-assets/'
};
