import { Injectable } from '@angular/core';
import { GeocomplyResultStateService } from '@bcf-libs/logic/others/src/lib/services/geocomply-result-state.service';
import { PlaceBetApiError } from '@bcf-logic-api-access/errors/place-bet-api-error';
import { asPromise } from '@bcf-v2-shared/misc/rx-helpers/as-promise';
import { PlaceBetGuardContract } from './place-bet-guard';

@Injectable({ providedIn: 'root' })
export class GeofenceGeocomplyPlaceBetGuard implements PlaceBetGuardContract {
  constructor(private _geocomplyResultStateService: GeocomplyResultStateService) {}

  public async canPlaceBet(): Promise<boolean> {
    const isInProgress: boolean = await asPromise(this._geocomplyResultStateService.geofenceInProgress$);
    if (isInProgress) {
      return false;
    }
    const errorState: string | undefined = await asPromise(
      this._geocomplyResultStateService.geofenceResultErrorMessage$
    );
    return errorState ? false : true;
  }
  public async getPlaceBetError(): Promise<PlaceBetApiError | undefined> {
    const isInProgress: boolean = await asPromise(this._geocomplyResultStateService.geofenceInProgress$);
    if (isInProgress) {
      return new PlaceBetApiError($localize`Geolocation in progress`, $localize`Please wait`, 0);
    }
    const errorState: string | undefined = await asPromise(
      this._geocomplyResultStateService.geofenceResultErrorMessage$
    );
    if (!errorState) {
      return undefined;
    }
    return new PlaceBetApiError(errorState, 'Geofence error', 0);
  }
}
