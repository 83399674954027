/* eslint-disable @typescript-eslint/typedef */
import { APP_INITIALIZER, EnvironmentProviders, Provider } from '@angular/core';
import { UrlSerializer } from '@angular/router';
import { basicInit, basicInitDeps, pwaInit, pwaInitDeps } from '@bcf-libs/app-utils/src/lib/basic-initializator';
import { routerInitAfterEnvSetup, routerInitAfterEnvSetupDeps } from '@bcf-libs/app-utils/src/lib/routes-initializator';
import { GeofenceGeocomplyPlaceBetGuard } from '@bcf-libs/logic/betting/src/lib/services/place-bet-guards/geofence-geocomply-place-bet-guard';
import { PlaceBetGuard } from '@bcf-libs/logic/betting/src/lib/services/place-bet-guards/place-bet-guard';
import { ModalMainContainerComponent } from '@bcf-libs/ui/container/components/sportsbook/letsbetmd/mobile-web/src/lib/modal-main-container/modal-main-container.component';
import { BCF_ROUTER_PROVIDER } from '@bcf-libs/ui/shared/src/lib/bcf-router/bcf-router-factory';
import { MODAL_EMBEDDED_CONTAINER } from '@bcf-libs/ui/shared/src/lib/modal-embedded-container/modal-embeddec-container.token';
import { TrailingSlashUrlSerializer } from '@bcf-v2-utilities/app-utils/trailing-slash-url-serializer';
import { provideI18nTranslations } from '@bcf-v2-utilities/i18n-translations/i18n-translations-provider';
import { settingsProviders } from './settings-providers';

const appCommonProviders: (Provider | EnvironmentProviders)[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: basicInit,
    deps: basicInitDeps,
    multi: true
  },
  {
    provide: APP_INITIALIZER,
    useFactory: pwaInit,
    deps: pwaInitDeps,
    multi: true
  },
  provideI18nTranslations(),
  {
    provide: APP_INITIALIZER,
    useFactory: routerInitAfterEnvSetup,
    deps: routerInitAfterEnvSetupDeps,
    multi: true
  },
  // remove @angular/platform-server/init from server.ts
  // {
  //   provide: APP_INITIALIZER,
  //   useFactory: routesWithCmsInit(() =>
  //     import(
  //       /* webpackChunkName: "cms-external-page" */ '@bcf-libs/ui/cms/components/sportsbook/famebetting/mobile-web/src/lib/cms-external-page/cms-external-page.module'
  //     ).then((mod) => mod.CmsExternalPageModule)
  //   ),
  //   deps: routesWithCmsInitDeps,
  //   multi: true
  // },
  BCF_ROUTER_PROVIDER,
  ...settingsProviders,
  { provide: UrlSerializer, useClass: TrailingSlashUrlSerializer },
  { provide: MODAL_EMBEDDED_CONTAINER, useValue: ModalMainContainerComponent },
  { provide: PlaceBetGuard, useClass: GeofenceGeocomplyPlaceBetGuard }
];

export const appBrowserProviders: (Provider | EnvironmentProviders)[] = [...appCommonProviders];

export const appServerProviders: (Provider | EnvironmentProviders)[] = [...appCommonProviders];
